const Step = ({
  number,
  title,
  paragraph,
}: {
  number: string;
  title: string;
  paragraph: string;
}) => {
  return (
    <div
      style={{
        position: "relative",
        width: "170px",
        height: "156px",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#0C1122",
        justifyContent: "space-around",
        borderRadius: "10px",
        padding: "32px",
        marginBottom: "40px",
        marginRight: "20px",
      }}
    >
      <div
        style={{
          backgroundColor: "#0C1122",
          height: "50px",
          width: "50px",
          transform: "rotate(45deg)",
          position: "absolute",
          borderRadius: "8px",
          left: 192,
        }}
      />
      <div>
        <h3 style={{ color: "#BA6516", fontWeight: 700, fontSize: "24px" }}>
          {number}
        </h3>
      </div>
      <div>
        <h4 style={{ fontSize: "16px", fontWeight: 700, color: "#DBDBDB" }}>
          {title}
        </h4>
      </div>
      <div>
        <p style={{ color: "#AAAAAA", fontSize: "14px", fontWeight: 400 }}>
          {paragraph}
        </p>
      </div>
    </div>
  );
};

export default Step;
