import Step from "../steps";

const Stepper = () => {
  const Copy = [
    `Discovery: Understand client's challenge and problem scope`,
    "Design: Design and review custom solution",
    `Development: Agile development with increments each sprint`,
    `Launch: User Acceptance Testing followed by deployment`,
    `Support: Assistance doesn't stop at deployment`,
  ];
  return (
    <div style={{ marginLeft: "5%", marginRight: "5%" }}>
      <h1
        style={{
          fontSize: "24px",
          fontWeight: 700,
          color: "white",
          marginBottom: "48px",
          marginTop: "80px",
        }}
      >
        How we work
      </h1>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "space-around",
          alignItems: "center",
        }}
      >
        {Copy.map((cp, i) => {
          return (
            <Step
              number={`0${i + 1}`}
              title={cp.split(":")[0]}
              paragraph={cp.split(":")[1]}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Stepper;
