import Button from "../../../components/button";
import Service from "../../../components/service-comp";
import { ReactComponent as Wd } from "../../../assets/wd.svg";
import { ReactComponent as Bc } from "../../../assets/bc.svg";
import { ReactComponent as App } from "../../../assets/app.svg";
import { ReactComponent as Nft } from "../../../assets/nft.svg";
import { useNavigate } from "react-router-dom";
import Skill from "../../../components/skill";
import { useWindowWidth } from "@react-hook/window-size";

function ServicesFull({ bool, text }: { bool: boolean; text: string }) {
  const onlyWidth = useWindowWidth();

  const navigate = useNavigate();
  return (
    <div
      style={{
        alignSelf: "center",
        display: "flex",
        flexDirection: "column",
        marginLeft: "5%",
        marginRight: "5%",
        width: `calc(min(1300px, 90%))`,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: "120px",
          marginBottom: "48px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <p style={{ color: "white", fontSize: "24px", fontWeight: "700" }}>
            {text}
          </p>
        </div>
        {bool && (
          <Button
            onClick={() => navigate("/services")}
            style={{
              width: onlyWidth < 800 ? "72px" : "108px",
              marginRight: onlyWidth < 800 ? "4px" : "15px",
              fontWeight: 700,
              transform: "translateY(-10px)",
            }}
          >
            See more
          </Button>
        )}
      </div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns:
            onlyWidth > 1300
              ? "repeat(4, 1fr)"
              : onlyWidth > 650
              ? "repeat(2, 1fr)"
              : "repeat(1, 1fr)",
          gridAutoRows: "400px",
          gap: " 10px",
          height: "auto",
          margin: onlyWidth < 650 ? "auto" : "0px",
        }}
      >
        <Service
          logo={<Wd />}
          title="Web Development"
          paragraph="We offer comprehensive web development services using the latest technologies and design principles to help you take your online presence to the next level and achieve your business goals."
        />
        <Service
          logo={<App />}
          title="Mobile App Development"
          paragraph="We provide end-to-end solutions, leveraging modern technologies and agile methodologies to deliver high-performance, secure, and customized applications."
        />

        <Service
          logo={<Bc />}
          title="Blockchain Development"
          paragraph="We offer comprehensive solutions for building decentralized applications, smart contracts, and blockchain-based solutions using cutting-edge technologies and industry best practices."
        />
        <Service
          logo={<Nft />}
          title="NFT Services"
          paragraph="We believe that non-fungible tokens (NFTs) have the potential to revolutionize the way we think about ownership and value. Our team can help you leverage this exciting new technology through custom NFT services."
        />
      </div>
      {bool && (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "space-around",
              alignItems: "center",
              height: "auto",
              marginTop: "48px",
            }}
          >
            <Skill
              header="Expert Developers"
              title="Be Bespoke"
              paragraph="With our experienced development team we're equipped to tackle your business challenges and deliver solutions that meet your unique needs."
            />
            <Skill
              header="Cutting-Edge Technologies"
              title="Modern"
              paragraph="We stay ahead of the curve by leveraging the latest technologies to deliver innovative digital solutions that give you a competitive edge."
            />
            <Skill
              header="Cost-Effective Rates "
              title="Accessible"
              paragraph="We make digital solutions accessible to businesses of all sizes. That's why we offer competitive prices that are affordable and flexible. "
            />
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}></div>
        </>
      )}
    </div>
  );
}

export default ServicesFull;
