import { ReactComponent as Net } from "../../../assets/net.svg";
import { ReactComponent as Prism } from "../../../assets/prism.svg";
import { ReactComponent as Cube } from "../../../assets/cube.svg";
import { ReactComponent as Sphere } from "../../../assets/sphere.svg";
import { ReactComponent as Pill } from "../../../assets/pill.svg";
import { ReactComponent as Talk } from "../../../assets/talk.svg";

import { AnimateKeyframes } from "react-simple-animate";
import Button from "../../../components/button";

import { useEffect, useState } from "react";
import Star from "../../../components/star";

function Hero() {
  const [width, setWidth] = useState<any>(0);

  useEffect(() => {
    const updateWidth = () => {
      const div = document.querySelector("#myDiv") as HTMLElement;
      setWidth(div.offsetWidth);
    };
    window.addEventListener("resize", updateWidth);
    updateWidth();
    return () => window.removeEventListener("resize", updateWidth);
  }, []);
  return (
    <div
      style={{
        width: "min(1300px, 94%)",
        display: "flex",
        flexDirection: "column",
        alignSelf: "center",
      }}
    >
      <div>
        <div style={{ position: "absolute", top: 241, left: 0.2 * width }}>
          <Star />
        </div>
        <div style={{ position: "absolute", top: 535, left: 0.5 * width }}>
          <Star />
        </div>

        <div style={{ position: "absolute", top: 100, left: 0.4 * width }}>
          <Star />
        </div>
        <div style={{ position: "absolute", top: 611, left: 0.7 * width }}>
          <Star />
        </div>
        <div style={{ position: "absolute", top: 440, left: 0.25 * width }}>
          <Star />
        </div>
        <div
          style={{
            position: "absolute",
            left: 0.7 * width,
            zIndex: 3,

            top: 100,
          }}
        >
          <AnimateKeyframes
            play
            duration={2}
            direction="alternate"
            keyframes={[
              "transform: translateY(0)",
              "transform: translateY(10px)",
            ]}
            iterationCount="infinite"
          >
            <Cube width={width / 16 + 30} height="auto" />
          </AnimateKeyframes>
        </div>
        <div
          style={{
            position: "absolute",
            left: 0.5 * width,
            zIndex: 3,
            top: 135,
          }}
        >
          <AnimateKeyframes
            play
            duration={20}
            direction="alternate"
            keyframes={["transform: rotate(0deg)", "transform: rotate(360deg)"]}
            iterationCount="infinite"
          >
            <Pill width={width / 21 + 30} height="auto" />
          </AnimateKeyframes>
        </div>
        <div
          style={{
            position: "absolute",
            left: 0.6 * width,
            zIndex: 3,
            top: 300,
          }}
        >
          <AnimateKeyframes
            play
            duration={4}
            direction="alternate"
            keyframes={[
              "transform: translateX(0)",
              "transform: translateX(25px)",
            ]}
            iterationCount="infinite"
          >
            <Sphere width={width / 18 + 30} height="auto" />
          </AnimateKeyframes>
        </div>

        <div
          style={{
            position: "absolute",
            left: 0.6 * width,
            zIndex: 3,
            top: 429,
          }}
        >
          <AnimateKeyframes
            play
            duration={7}
            direction="alternate"
            keyframes={[
              "transform: rotate(-14deg)",
              "transform: rotate(18deg)",
            ]}
            iterationCount="infinite"
          >
            <Prism width={width / 11 + 30} height="auto" />
          </AnimateKeyframes>
        </div>
        <div
          style={{
            position: "relative",
            top: -50,
            zIndex: -1,
            width: "0px",
            height: "0px",
          }}
        >
          <Net width={width < 1200 ? width * 0.8 : 1200} />
        </div>
      </div>
      <div
        style={{
          position: "relative",
          zIndex: 20,
          maxWidth: "600px",
          marginTop: width > 600 ? "150px" : "150px",
        }}
      >
        <h1
          style={{
            fontSize: width > 900 ? "48px" : "36px",
            fontWeight: "700",
            lineHeight: "72px",
            fontFamily: "Overpass Mono",
            zIndex: 20,
          }}
        >
          Digital Expertise, Delivered
        </h1>
      </div>
      <div style={{ zIndex: 1, width: "300px" }}>
        <h3 style={{ fontWeight: 400, fontSize: "16px", lineHeight: "24px" }}>
          Start your journey with us now
        </h3>
      </div>
      <Button style={{ marginTop: "20px" }}>
        <Talk height={20} width={20} style={{ marginRight: "10px" }} />
        Let’s talk
      </Button>
    </div>
  );
}

export default Hero;
