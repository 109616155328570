import { useWindowWidth } from "@react-hook/window-size";
import Geo from "../../assets/georgia.png";
import Us from "../../assets/usa.png";
import Skill from "../../components/skill";

import "./leadership.css";

function Offices() {
  return (
    <div style={{ backgroundColor: "#060912", width: "100%" }}>
      <div
        style={{
          backgroundColor: "#060912",
          display: "flex",
          flexDirection: "column",
          paddingTop: "80px",
        }}
      >
        <p
          style={{
            color: "#DBDBDB",
            fontWeight: 700,
            fontSize: "24px",
            marginLeft: "4%",
          }}
        >
          {/* Team & Offices */}
          Offices
        </p>
        <div
          style={{
            backgroundColor: "#060912",
            display: "flex",
            flexDirection: "row",
            paddingTop: "56px",
            justifyContent: "space-evenly",
            flexWrap: "wrap",
            width: "100%",
          }}
        >
          <Teams
            name={"USA"}
            subname="Washington, DC, USA"
            roles={[
              "Top management",
              "Support",
              "Account manager",
              "Business dev. managers",
            ]}
            img={Us}
          />
          <Teams
            name={"Georgia"}
            subname="Tbilisi, Georgia"
            roles={[
              "Developers",
              "Designers",
              "Product managers",
              "Testing engineers",
            ]}
            img={Geo}
          />

          <div
            style={{
              marginTop: "120px",
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "space-evenly",
              alignItems: "center",
              height: "auto",
              width: "100%",
            }}
          >
            <Skill
              header="Expert Developers"
              title="Be Bespoke"
              paragraph="With our experienced development team we're equipped to tackle your business challenges and deliver solutions that meet your unique needs."
            />
            <Skill
              header="Cutting-Edge Technologies"
              title="Modern"
              paragraph="We stay ahead of the curve by leveraging the latest technologies to deliver innovative digital solutions that give you a competitive edge."
            />
            <Skill
              header="Cost-Effective Rates "
              title="Accessible"
              paragraph="We make digital solutions accessible to businesses of all sizes. That's why we offer competitive prices that are affordable and flexible. "
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Offices;

const Teams = ({
  name,
  subname,
  roles,
  img,
}: {
  name: string;
  subname: string;
  roles: Array<string>;
  img: any;
}) => {
  const onlyWidth = useWindowWidth();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#0C1122",
        padding: "20px",
        borderRadius: "10px",
        minWidth: "305px",
        marginBottom: "20px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <p style={{ color: "#DBDBDB", fontSize: "20px", fontWeight: 700 }}>
            {name}
          </p>
          <p style={{ color: "#AAAAAA", fontSize: "14px", fontWeight: 400 }}>
            {subname}
          </p>
        </div>
        <img src={img} width={90} height={50} alt={""} />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#121A34",
          borderRadius: "10px",
          marginTop: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: onlyWidth > 1400 ? "row" : "column",
          }}
        >
          <Position name={roles[0]} />
          <Position name={roles[2]} />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: onlyWidth > 1400 ? "row" : "column",
          }}
        >
          <Position name={roles[1]} />
          <Position name={roles[3]} />
        </div>
      </div>
    </div>
  );
};

const Position = ({ name }: { name: string }) => {
  return (
    <div
      style={{
        height: "72px",
        width: "305px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
        border: "1px solid #1E2847",
      }}
    >
      <div
        style={{
          width: "8px",
          height: "8px",
          borderRadius: "50%",
          backgroundColor: "#BA6516",
          marginRight: "12px",
          boxShadow: "0px 0px 8px #BA6516",
        }}
      />
      <p style={{ color: "#DBDBDB" }}>{name}</p>
    </div>
  );
};
