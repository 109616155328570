import Offices from "./Offices";
import { PageHero } from "../../components/pageHero";
import Stepper from "../../components/stepper";
import whoWeAre from "../../assets/whoWeAreHero.png";
import { memo } from "react";

function AboutPage() {
  return (
    <div
      style={{
        paddingBottom: "120px",
      }}
    >
      <PageHero
        title={"Who we are"}
        subtext={`Griffin Labs was started in the summer of 2022 when four friends
            decided to apply their skills and experience to create digital
            solutions that create value for businesses. We currently have
            offices in two countries, with our management located in Washington
            DC and our development team located in Tbilisi, Georgia. Our
            expertise extends across a range of digital services, including web
            development, mobile app development, blockchain solutions, and more.
            We stay ahead of the curve by embracing the latest technologies and
            industry trends, so we can deliver solutions that are cutting-edge
            and effective.`}
        img={whoWeAre}
      />

      {/* <Leadership /> */}
      <Offices />
      <Stepper />
    </div>
  );
}

export default memo(AboutPage);
