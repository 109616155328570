import { useWindowWidth } from "@react-hook/window-size";
import Star from "../../components/star";

function min(num: number, num2: number): string | number | undefined {
  if (num < num2) return num;
  return num2;
}

type Props = {
  title: string;
  subtext: string;
  img: string;
};

export const PageHero = ({ title, subtext, img }: Props) => {
  const onlyWidth = useWindowWidth();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: onlyWidth > 1100 ? "row" : "column",
        marginLeft: "0px",
        justifyContent: onlyWidth > 1100 ? "space-between" : "center",
        paddingLeft: onlyWidth > 900 ? "80px" : "14px",
        paddingRight: onlyWidth > 900 ? "80px" : "14px",
        paddingBottom: "80px",
        paddingTop: "100px",

        backgroundColor: "#0C1122",
      }}
    >
      {onlyWidth > 1350 ? (
        <>
          <div
            style={{ position: "absolute", top: 241, left: 0.35 * onlyWidth }}
          >
            <Star />
          </div>
          <div
            style={{ position: "absolute", top: 253, left: 0.5 * onlyWidth }}
          >
            <Star />
          </div>

          <div
            style={{ position: "absolute", top: 100, left: 0.4 * onlyWidth }}
          >
            <Star />
          </div>
          <div
            style={{ position: "absolute", top: 421, left: 0.32 * onlyWidth }}
          >
            <Star />
          </div>
        </>
      ) : null}

      <div
        style={{
          maxWidth: "400px",
          display: "flex",
          alignContent: "center",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <p
          style={{
            color: "#DBDBDB",
            fontSize: "48px",
            fontWeight: 700,
            maxWidth: "453px",
            marginBottom: "20px",
          }}
        >
          {title}
        </p>

        <p
          style={{
            color: "#DBDBDB",
            fontSize: "14px",
            fontWeight: 400,
            marginBottom: "20px",
          }}
        >
          {subtext}
        </p>
      </div>

      <img
        src={img}
        width={min(600, onlyWidth * 0.9)}
        height={"auto"}
        style={{ borderRadius: "10px" }}
        alt=""
      />
    </div>
  );
};
