const Skill = ({
  header,
  title,
  paragraph,
}: {
  header: string;
  title: string;
  paragraph: string;
}) => {
  return (
    <div
      style={{
        maxWidth: "340px",
        height: "148px",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#0C1122",
        borderRadius: "10px",
        padding: "32px",
        marginBottom: "40px",
      }}
    >
      <div style={{ marginBottom: "8px" }}>
        <h3 style={{ color: "#BA6516", fontWeight: 400, fontSize: "14px" }}>
          {header}
        </h3>
      </div>
      <div style={{ marginBottom: "16px" }}>
        <h4
          style={{
            fontSize: "20px",
            fontWeight: 700,
            color: "#DBDBDB",
          }}
        >
          {title}
        </h4>
      </div>
      <div>
        <p style={{ color: "#AAAAAA" }}>{paragraph}</p>
      </div>
    </div>
  );
};

export default Skill;
