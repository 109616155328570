import React from "react";
import "./button.css";
const Button = ({
  children,
  style,
  onClick,
}: {
  children: React.ReactNode;
  style?: any;
  onClick?: () => void;
}) => {
  return (
    <button className={"buttonstyle"} style={style} onClick={onClick}>
      {children}
    </button>
  );
};

export default Button;
