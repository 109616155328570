const Service = ({
  logo,
  title,
  paragraph,
}: {
  logo: any;
  title: string;
  paragraph: string;
}) => {
  return (
    <div
      style={{
        width: "240px",
        height: "280px",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#0C1122",
        borderRadius: "10px",
        padding: "32px",
        marginBottom: "40px",
      }}
    >
      <div
        style={{
          backgroundColor: "#121A34",
          height: "56px",
          width: "56px",
          borderRadius: "10px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "50px",
        }}
      >
        {logo}
      </div>
      <div
        style={{ marginBottom: "24px", fontWeight: "700", color: "#DBDBDB" }}
      >
        <h4>{title}</h4>
      </div>
      <div style={{ color: "#AAAAAA" }}>
        <p>{paragraph}</p>
      </div>
    </div>
  );
};

export default Service;
