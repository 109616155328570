import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useWindowWidth } from "@react-hook/window-size";
import { ReactComponent as Menu } from "../../assets/menu.svg";
import { ReactComponent as Close } from "../../assets/close.svg";
import { ReactComponent as Hand } from "../../assets/hand.svg";

const pathnames = {
  home: "/",
  services: "/services",
  about: "/about",
};

const Navbar = () => {
  const modalRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const onlyWidth = useWindowWidth();
  const cur = useLocation();
  const [isHovering, setIsHovering] = useState(false);
  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  useEffect(() => {
    setOpen(false);
  }, [cur.pathname]);

  const handleClickOutside = (event: { target: any }) => {
    // check if click occurred outside of modal content
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    // add event listener to document on mount
    document.addEventListener("mousedown", handleClickOutside);

    // remove event listener on unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <nav
      style={{
        zIndex: 1,
        position: "absolute",
        backgroundColor: "transparent",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: "15px",
        paddingBottom: "15px",
      }}
    >
      <ul
        style={{
          display: "flex",
          flexDirection: "row",
          height: "60px",
          width: `calc(min(1290px, 90vw))`,
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            height: "60px",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={() => navigate("/")}
        >
          <img
            src={require("../../assets/logo.png")}
            width={78}
            height={40}
            alt="logo"
          />
          <h1 style={{ color: "white", marginLeft: "16px" }}>Griffin Labs</h1>
        </div>
        {onlyWidth > 600 ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-evenly",
              width: "400px",
              marginLeft: "auto",
              marginRight: "auto",
              marginBottom: "8px",
            }}
          >
            <Li
              bool={cur.pathname === pathnames.home}
              path={pathnames.home}
              name={"Home"}
            />
            <Li
              bool={cur.pathname === pathnames.about}
              path={pathnames.about}
              name={"Who we are"}
            />

            <Li
              bool={cur.pathname === pathnames.services}
              path={pathnames.services}
              name={"Services"}
            />
          </div>
        ) : (
          <div style={{ marginLeft: "auto" }}>
            <div onClick={() => setOpen(true)} style={{ cursor: "pointer" }}>
              <Menu color="white" />
            </div>
            {open && (
              <div
                style={{
                  position: "absolute",
                  height: "245px",
                  marginLeft: "auto",
                  marginRight: "auto",
                  top: 20,
                  left: "50%",
                  transform: "translateX(-50%)",
                  backgroundColor: "#121A34",
                  width: "80vw",
                  borderRadius: "10px",
                  padding: "28px",
                  display: "flex",
                  flexDirection: "column",
                }}
                ref={modalRef}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <h2
                    style={{
                      fontSize: "24px",
                      fontWeight: 800,
                      color: "#DBDBDB",
                    }}
                  >
                    Navigation
                  </h2>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    <Close />
                  </div>
                </div>
                <div
                  style={{
                    height: "1px",
                    backgroundColor: "#1E2847",
                    width: "100%",
                    marginTop: "28px",
                    marginBottom: "28px",
                  }}
                />

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "260px",
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginBottom: "28px",
                  }}
                >
                  <div
                    onClick={() => (window.location.href = pathnames.home)}
                    style={{
                      all: "unset",
                      fontSize: "14px",
                      fontWeight: 700,
                      color:
                        cur.pathname === pathnames.home ? "white" : "#B1B1B1",
                      cursor: "pointer",
                    }}
                  >
                    Home
                  </div>
                  <div
                    onClick={() => (window.location.href = pathnames.about)}
                    style={{
                      all: "unset",
                      fontSize: "14px",
                      fontWeight: 700,
                      color:
                        cur.pathname === pathnames.about ? "white" : "#B1B1B1",
                    }}
                  >
                    Who are we
                  </div>
                  <div
                    onClick={() => (window.location.href = pathnames.services)}
                    style={{
                      all: "unset",
                      fontSize: "14px",
                      fontWeight: 700,
                      color:
                        cur.pathname === pathnames.services
                          ? "white"
                          : "#B1B1B1",
                    }}
                  >
                    Services
                  </div>
                </div>
                <button
                  style={{
                    position: "relative",
                    border: "unset",
                    cursor: "pointer",
                    width: "100%",
                    height: "48px",
                    padding: "14px 24px",
                    backgroundColor: "#4A16BA",
                    borderRadius: "10px",
                    color: "white",
                    fontSize: "14px",
                    fontWeight: 700,
                    whiteSpace: "nowrap",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={() =>
                    window.open("https://forms.gle/opPjWedxxXJBe8Ep7", "_blank")
                  }
                >
                  <Hand style={{ marginRight: "8px" }} />
                  Contact us
                </button>
              </div>
            )}
          </div>
        )}

        {onlyWidth > 600 && (
          <button
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            style={{
              display: "flex",
              position: "relative",
              border: isHovering ? "2px solid white" : "2px solid #4A16BA",
              cursor: "pointer",
              width: "149px",
              height: "48px",
              padding: "14px 24px",
              backgroundColor: "#4A16BA",
              borderRadius: "10px",
              color: "white",
              alignItems: "center",
              fontWeight: "700",
              whiteSpace: "nowrap",
            }}
            onClick={() =>
              // eslint-disable-next-line no-restricted-globals
              window.open("https://forms.gle/opPjWedxxXJBe8Ep7", "_blank")
            }
          >
            <Hand style={{ marginRight: "8px" }} />
            <span>Contact Us</span>
          </button>
        )}
      </ul>
    </nav>
  );
};

const Li = ({
  bool,
  path,
  name,
}: {
  bool: boolean;
  path: string;
  name: string;
}) => {
  const [isHovering, setIsHovering] = useState(false);
  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };
  const navigate = useNavigate();

  return (
    <div
      className="hoverer"
      // onClick={() => window.open(path, "_blank")}
      onClick={() => navigate(path)}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{
        all: "unset",
        color: bool ? "white" : isHovering ? "#bbbbbb" : "grey",
        cursor: "pointer",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginRight: "8px",
      }}
    >
      {bool && (
        <div
          style={{
            height: "4px",
            width: "4px",
            borderRadius: "50%",
            backgroundColor: "white",
            marginRight: "10px",
          }}
        />
      )}

      {name}
    </div>
  );
};

export default Navbar;
