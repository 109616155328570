import { useWindowWidth } from "@react-hook/window-size";

const Stack = ({
  logo,
  title,
  techs,
}: {
  logo: any;
  title: string;
  techs: Array<string>;
}) => {
  const onlyWidth = useWindowWidth();

  return (
    <div
      style={{
        minWidth: onlyWidth < 600 ? "0px" : "250px",
        display: "flex",
        flexDirection: "column",
        alignContent: "flex-end",
        marginLeft: onlyWidth < 600 ? "auto" : "0px",
        marginRight: onlyWidth < 600 ? "auto" : "0px",
      }}
    >
      <div
        style={{
          backgroundColor: "#121A34",
          height: "56px",
          width: "56px",
          borderRadius: "10px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "20px",
          marginTop: "64px",
        }}
      >
        {logo}
      </div>
      <div>
        <h4 style={{ color: "#DBDBDB", fontSize: "20px", fontWeight: 700 }}>
          {title}
        </h4>
      </div>
      <div style={{ marginTop: "24px" }}>
        {techs.map((el) => {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "left",
                alignItems: "center",
                marginTop: "16px",
              }}
            >
              <div
                style={{
                  width: "8px",
                  height: "8px",
                  borderRadius: "50%",
                  backgroundColor: "#BA6516",
                  marginRight: "12px",
                  boxShadow: "0px 0px 8px #BA6516",
                }}
              />
              <p
                style={{ color: "#B1B1B1", fontSize: "14px", fontWeight: 400 }}
              >
                {el}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Stack;
