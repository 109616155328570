import { ReactComponent as Wd } from "../../assets/wd.svg";
import { ReactComponent as Bc } from "../../assets/bc.svg";
import { ReactComponent as App } from "../../assets/app.svg";
import { ReactComponent as Backend } from "../../assets/backend.svg";
import Stack from "./Stack";
import { PageHero } from "../../components/pageHero";
import ServicesFull from "../main/our-services";
import Stepper from "../../components/stepper";
import servHero from "../../assets/griffin-labs-cover.jpg";
import { useWindowWidth } from "@react-hook/window-size";
import { memo } from "react";

function ServicesPage() {
  const onlyWidth = useWindowWidth();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        marginBottom: "120px",
      }}
    >
      <PageHero
        title={"Our services"}
        subtext={` At Griffin Labs, our close-knit team of developers is passionate about
          creating digital products that create value for businesses and their
          users. We're dedicated to tackling even the most complex challenges
          head-on, leveraging innovative design and cutting-edge technology to
          deliver success on every project we undertake. With an unwavering
          focus on exceeding expectations, we're always pushing the boundaries
          of what's possible in the digital landscape.`}
        img={servHero}
      />
      <ServicesFull bool={false} text={"What we do"} />
      <Stepper />
      <div
        style={{
          backgroundColor: "#0C1122",
          paddingTop: "80px",
          paddingBottom: "80px",
          paddingLeft: "5%",
          paddingRight: "5%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <h1
            style={{
              color: "#DBDBDB",
              fontSize: "24px",
              fontWeight: 700,
              marginBottom: "20px",
            }}
          >
            Our tech stack
          </h1>
          <h4
            style={{
              color: "#B1B1B1",
              fontSize: "14px",
              fontWeight: 400,
              marginBottom: "20px",
            }}
          >
            Here are development languages, which we use
          </h4>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: onlyWidth < 600 ? "column" : "row",
            flexWrap: "wrap",
            justifyContent: "space-around",
          }}
        >
          <Stack
            logo={<Wd />}
            title="Frontend"
            techs={["Javascript", "React", "Angular", "Next.js", "HTML/CSS"]}
          />
          <Stack
            logo={<Backend />}
            title="Backend"
            techs={[".NET", "Python", "Django", "Flask", "Node.js", "PHP"]}
          />
          <Stack
            logo={<App />}
            title="Mobile app"
            techs={["React Native", "Swift", "Kotlin"]}
          />
          <Stack
            logo={<Bc />}
            title="NFT Services"
            techs={["Ethereum", "Solana", "BNB Chain"]}
          />
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "column" }}></div>
    </div>
  );
}

export default memo(ServicesPage);
