import { ReactComponent as Tw } from "../../assets/twt.svg";
import { ReactComponent as Inst } from "../../assets/inst.svg";
import { useWindowWidth } from "@react-hook/window-size";
import { ReactComponent as Hand } from "../../assets/hand.svg";
import { ReactComponent as Zip } from "../../assets/zip.svg";
import IconButton from "../button2";
import { useState } from "react";

const Footer = () => {
  const currentTime = new Date();
  const onlyWidth = useWindowWidth();
  const [isHovering, setIsHovering] = useState(false);
  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  return (
    <>
      <div
        style={{
          justifySelf: "center",
          maxWidth: "1800px",
          alignSelf: "center",
          position: "relative",
        }}
      >
        <div
          style={{
            position: "relative",
            display: "flex",
            minHeight: "252px",
            backgroundColor: "#0C1122",
            flexDirection: onlyWidth > 600 ? "row" : "column",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
            marginLeft: onlyWidth < 900 ? "0px" : "68px",
            marginRight: onlyWidth < 900 ? "0px" : "68px",
            paddingBottom: "32px",
            marginBottom: "16px",
            zIndex: 2,
          }}
        >
          <Zip
            style={{
              position: "absolute",
              zIndex: 3,
              width: "100%",
            }}
          />

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "min(400px, 95%)",
              marginRight: "48px",
              justifyContent: "center",
              alignContent: "center",
              paddingLeft: "68px",
              marginBottom: "20px",
              zIndex: 5,
            }}
          >
            <h1
              style={{
                color: "#DBDBDB",
                fontWeight: 700,
                fontSize: onlyWidth < 900 ? "32px" : "48px",
                marginBottom: "4px",
              }}
            >
              Got a project?
            </h1>
            <div>
              <p
                style={{ color: "#B1B1B1", fontWeight: 400, fontSize: "16px" }}
              >
                Let us know a bit about yourself, your business and your needs.
                We will get back to you ASAP.
              </p>
            </div>
          </div>
          <div
            style={{ display: "flex", flexDirection: "column", width: "361px" }}
          >
            <button
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              style={{
                zIndex: 100,
                position: "relative",
                border: isHovering ? "2px solid white" : "2px solid #4A16BA",
                cursor: "pointer",
                width: "100%",
                height: "48px",
                padding: "14px 24px",
                backgroundColor: "#4A16BA",
                borderRadius: "10px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "white",
                fontSize: "14px",
                fontWeight: 900,
              }}
              onClick={() =>
                window.open("https://forms.gle/opPjWedxxXJBe8Ep7", "_blank")
              }
            >
              <Hand style={{ marginRight: "8px" }} /> Contact Us
            </button>
          </div>
        </div>
      </div>
      <div
        style={{
          height: "100px",
          display: "flex",
          flexDirection: onlyWidth > 600 ? "row" : "column",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: "transparent",
          color: "white",
          paddingLeft: "68px",
          paddingRight: "68px",
          marginBottom: "40px",
          marginTop: "40px",
        }}
      >
        <div
          style={{
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "transparent",
            display: "flex",
            flexDirection: "row",
            width: "104px",
          }}
        >
          {/* <IconButton path="">
           <Fb />
         </IconButton> */}
          <IconButton path="https://twitter.com/griffin_labs?fbclid=IwAR39Vr4JLPwLu-KqEG__INxUqctHXVfNk5n3HgPSfkTW93b0IC1rGHkvyi8">
            <Tw />
          </IconButton>
          <IconButton path="https://www.instagram.com/griffin_labs/?fbclid=IwAR1rwq3gduhba6UUs2IkgKjHQa1WWOl1Vt_UmOE8KZWAEb7pZ0MRPO1NYpQ">
            <Inst />
          </IconButton>
        </div>
        <p>All rights reserved. {currentTime.getFullYear()}</p>
      </div>
    </>
  );
};

export default Footer;
