import { Outlet } from "react-router-dom";
import Footer from "../footer";
import Navbar from "../navbar";

const Layout = () => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#060912",
      }}
    >
      <Navbar />
      <body
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignSelf: "center",
        }}
      >
        <Outlet />
      </body>
      <Footer />
    </div>
  );
};

export default Layout;
