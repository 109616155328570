import React, { useState } from "react";
const IconButton = ({
  children,
  path,
}: {
  children: React.ReactNode;
  path: string;
}) => {
  const [isHovering, setIsHovering] = useState(false);
  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  return (
    <div
      style={{
        display: "flex",
        height: "44px",
        width: "44px",
        cursor: "pointer",
        background: isHovering
          ? "#4A16BA"
          : "linear-gradient(180deg, #4A16BA 0%, #B8631B 100%)",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "10px",
      }}
      onClick={() => window.open(path, "_blank")}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}
    </div>
  );
};

export default IconButton;
