import ServicesFull from "./our-services";
import Hero from "./hero";
import { memo } from "react";

function Main() {
  return (
    <div
      id="myDiv"
      style={{
        marginTop: "20px",
        paddingTop: "20px",
        color: "white",
        width: "100%",
        zIndex: 0,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Hero />
      <div style={{ marginTop: "80px" }} />
      <ServicesFull bool={true} text={"Our services"} />
      {/* <About /> */}
      {/* <Mission /> */}
    </div>
  );
}

export default memo(Main);
